<template>
    <m-main-layout>
        <el-button size="small" type="primary" slot="right-btn" @click="$router.push({path:'/rule/group/list'})">角色列表
        </el-button>
        <div class="page-content-main">
            <div class="dark-2">编辑 <b class="success f-b">{{ formData.name }}</b> 的权限</div>
            <div class="line"></div>
            <el-tree
                :data="dataList"
                show-checkbox
                node-key="id"
                default-expand-all
                :expand-on-click-node="false"
                check-on-click-node
                :props="defaultProps"
                :default-checked-keys="treeChecked"
                @check-change="checkChange" ref="tree">
            </el-tree>
            <div class="padding-top-bottom">
                <el-button type="primary" @click="submitForm">保存</el-button>
                <el-button type="" @click="$router.back()">返回</el-button>
            </div>
        </div>
    </m-main-layout>
</template>

<script>
export default {
    name: "MemberList",
    data() {
        return {
            dataList: [],
            formData: {
                id: '',
                rule_ids: [],
                name: ''
            },
            treeChecked: [],
            defaultProps: {
                children: 'child',
                label: 'title'
            }
        }
    },
    created() {
        this.formData.id = this.$route.query.id ? this.$route.query.id : 0;
    },
    methods: {
        getList() {
            let _this = this;
            this.dataList = [];
            this.$api.ruleList(null, function (res) {
                _this.dataList = res;
            })
        },
        del(id) {
            let _this = this;
            this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                _this.$api.ruleGroupDel({id: id}, function (res) {
                    _this.getList();
                })
            }).catch(() => {
            });
        },
        getDetail() {
            let _this = this;
            this.$api.ruleGroupDetail({id: _this.formData.id}, function (res) {
                // console.log(res);
                _this.formData.id = res.id;
                _this.formData.name = res.name;
                _this.formData.rule_ids = res.rule_ids;

                if (_this.formData.rule_ids) {
                    _this.treeChecked = _this.formData.rule_ids.split(",");
                }

                // console.log(_this.treeChecked);
                // _this.dataList = res;
            })
        },
        checkChange() {
            let _leaf_ids = this.$refs.tree.getCheckedKeys(true);
            // console.log(_leaf_ids);

            // console.log(this.$refs.tree.getHalfCheckedKeys());
            // let _halfChecked_ids = this.$refs.tree.getHalfCheckedKeys();


            // let _ids = _leaf_ids.concat(_halfChecked_ids);
            let _ids = _leaf_ids;

            // console.log('========_halfChecked_ids====')
            // console.log(_halfChecked_ids);
            //
            // console.log('========_ids====')
            // console.log(_ids);

            // console.log('========_leaf_ids====')
            // console.log(_leaf_ids);

            // if (_leaf_ids.length > 0) {
            //     _leaf_ids.sort(function sortNumber(a, b) {
            //         return a - b
            //     });
            //
            //     this.formData.rule_ids = _leaf_ids.join(",");
            // } else {
            //     this.formData.rule_ids = '';
            // }

            if (_ids.length > 0) {

                _ids = this.$utils.removal(_ids);
                _ids.sort(function sortNumber(a, b) {
                    return a - b
                });

                this.formData.rule_ids = _ids.join(",");
            } else {
                this.formData.rule_ids = '';
            }

            // console.log(this.formData.rule_ids);
        },
        submitForm() {
            let _this = this;
            this.$api.ruleGroupEdit(_this.formData, function (res) {
                _this.$router.replace({path: '/rule/group'})
            })
        }
    },
    mounted() {
        this.getList();

        if (this.formData.id !== 0) {
            this.getDetail();
        }
    }
}
</script>
<style>
.el-tree > .el-tree-node {padding-bottom: 10px;margin-bottom: 10px;border-bottom: 1px solid #ccc;}

.el-tree > .el-tree-node > .el-tree-node__children > .el-tree-node > .el-tree-node__children {flex-wrap:wrap;display: flex !important;}

/*.el-tree .el-tree-node__children{display: flex !important;}*/
</style>
